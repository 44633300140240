import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "reportUnits",
})
export class ReportUnitsPipe implements PipeTransform {
  transform(value: string, report: number): string {
    if (report === 10 || report === 9) {
      if (value.length < 4) {
        return `${this.getGermanNumericFormat(value)} MB`;
      } else {
        if (value.length < 7) {
          const dataNumber = parseInt(value, 10);
          const response = (dataNumber / 1024).toFixed(2);
          return `${this.getGermanNumericFormat(response)} GB`;
        } else {
          const dataNumber = parseInt(value, 10);
          const response = (dataNumber / 1024 / 1024).toFixed(2);
          return `${this.getGermanNumericFormat(response)} TB`;
        }
      }
    } else {
      let dataNumber = this.getGermanNumericFormat(value);
      return dataNumber;
    }
  }

  public getGermanNumericFormat(number) {
    let dataNumber = new Intl.NumberFormat("de", {
      minimumFractionDigits: 2,
    }).format(Number(number));
    return dataNumber.replace(",00", "");
  }
}
