<div>
  <div style="position: relative; top: 0; float: right">
    <span mat-dialog-close style="cursor: pointer; float: right">
      <mat-icon>clear</mat-icon>
    </span>
  </div>

  <div style="padding-left: 30px; padding-right: 30px">
    <h5 mat-dialog-title>Neues Passwort setzen</h5>
    <mat-dialog-content>
      <p>Sie erhalten eine E-Mail an Ihre Adresse mit dem Link, um ein neues Passwort zu setzen. Dabei werden Sie ausgeloggt.</p>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions align="center" class="dialog-btn-container">
    <button mat-flat-button [mat-dialog-close]="true" class="btn-active">Passwort ändern</button>
  </mat-dialog-actions>
</div>
