import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Injectable()
export class UserRoleGuard  {
  constructor(private router: Router, private jwtAuth: JwtAuthService, private snack: MatSnackBar) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var user = this.jwtAuth.getUser();

    if (user && route.data && route.data.roles && route.data.roles.includes(user.role)) {
      return true;
    } else {
      this.snack.open("You do not have access to this page!", "OK");
      return false;
    }
  }
}
