import { Component, OnInit, OnDestroy, AfterViewInit, DebugElement } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { SharedService } from "app/shared/services/shared.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { SetPasswordDialogComponent } from "./set-password-dialog/set-password-dialog.component";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  DSV_USER: String = this.shared.environment.dsv_user;
  public menuItems: any[];
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  public adminList = null;

  constructor(private navService: NavigationService, public themeService: ThemeService, private layout: LayoutService, public shared: SharedService, public dialog: MatDialog) {}

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;
    });

    if (this.shared.jwtAuth.hasTenantList()) {
      // super user or SCS USER ADM
      this.navService.publishNavigationChange(this.shared.jwtAuth.user);
      this.shared.changeTenantObservable.subscribe((tenant) => {
        this.navService.publishNavigationChange(tenant);
        if (this.DSV_USER === tenant.tenant) {
          this.adminList = null;
        } else {
          this._getAdminList(tenant);
        }
      });
    } else {
      this.navService.publishNavigationChange(this.shared.jwtAuth.user);
      this._getAdminList();
    }
    this.layoutConf = this.layout.layoutConf;
  }

  private _getAdminList(tenant?): void {
    this.shared.jwtAuth.getAdminList(tenant).subscribe((response) => {
      if (response && response.admins) {
        this.adminList = response.admins;
      }
    });
  }

  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }

  public openSetPasswordDialog(): void {
    const dialogRef = this.dialog.open(SetPasswordDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // call set password endpoint
        this.shared.jwtAuth.setPasswordUserAuth().subscribe((response) => {
          this._openConfirmSetPasswordDialog();
        });
      }
    });
  }

  private _openConfirmSetPasswordDialog(): void {
    const dialogRef = this.dialog.open(ConfirmSetPasswordDialog);
    dialogRef.afterClosed().subscribe((result) => {
      this.shared.jwtAuth.signout();
    });
  }
}

@Component({
  template: `
    <div>
      <div style="padding-left: 30px; padding-right: 30px">
        <h5 mat-dialog-title>Neues Passwort setzen</h5>
        <p>Ihnen wurde eine E-Mail zugesandt, mit einem Link, um ein neues Passwort zu setzen. Der Link ist nur 4 Stunden gültig.</p>
      </div>
    </div>
  `,
})
export class ConfirmSetPasswordDialog {
  constructor() {}
}
