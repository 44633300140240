import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError, Subject } from "rxjs";
import { JwtAuthService } from "./auth/jwt-auth.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Tenant } from "../models/tenant.model";
import { jsPDF, TextOptionsLight } from "jspdf";
import autoTable from "jspdf-autotable";
import { EnvService } from "app/env.service";
import { AbstractControl } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  public environment = this.env;
  API_URL: String = this.env.apiAuthURL;
  public settingsEditing = false;
  public searchText: "";
  public tenantList: Tenant[] = [];
  public currentTenant: Tenant;
  private temp: Tenant[] = [];
  private changeTenantSubject = new Subject<any>();
  changeTenantObservable = this.changeTenantSubject.asObservable();

  constructor(private http: HttpClient, public jwtAuth: JwtAuthService, private snack: MatSnackBar, private translate: TranslateService, private env: EnvService) {}

  public loadTenantList(page: string) {
    let hasPermissions = false;
    if (page === "ACCOUNTING" || page === "REPORTING" || page === "ADMINISTRATION") {
      hasPermissions = this.jwtAuth.hasTenantList();
    }
    this._setTenantList(hasPermissions);
  }

  private _setTenantList(hasPermissions: boolean) {
    if (hasPermissions) {
      this.getTenantslist().subscribe((response) => {
        let tenant = response[0];
        this.tenantList = response;
        this.temp = response;
        if (this.currentTenant) {
          tenant = response.find((x) => x.id === this.currentTenant.id);
        }
        this.setTenant(tenant);
      });
    } else {
      this.currentTenant = null;
      this.searchText = "";
      this.tenantList = [];
      this.temp = [];
    }
  }

  public setTenant(tenant) {
    this.currentTenant = tenant;
    this.changeTenant(tenant);
  }

  searchTenant(event?): void {
    const searchText = this.searchText.toLowerCase();
    const temp = this.temp.filter((d) => {
      return d.tenant.toLowerCase().indexOf(searchText) !== -1 || !searchText;
    });
    this.tenantList = temp;
    if (event) {
      setTimeout(() => {
        event.target.focus();
      }, 100);
    }
  }

  closeSuggestions(event) {
    this.searchText = "";
    this.searchTenant();
  }

  public getTenantslist() {
    return this.http.get(`${this.API_URL}/tenantlist`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        this.errorController(error);
        return throwError(error);
      })
    );
  }

  public changeTenant(tenant: any) {
    this.currentTenant = tenant;
    this.changeTenantSubject.next(tenant);
  }

  public successAlert(message) {
    this.snack.open(message, "X", {
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
      panelClass: ["snack-success"],
    });
  }

  public errorController(error, show: boolean = true) {
    if (error) {
      if (error && error.status && error.status === 401) {
        this.jwtAuth.signout();
      } else {
        if (show) {
          let errorMesg = this.translate.instant(error.error.errorCode);
          this.snack.open(errorMesg, "X", {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        }
      }
    } else {
      this.snack.open("Fehler in der Applikation. Bitte überprüfen Sie die Log-Dateien.", "X", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  public getGermanNumericFormat(number) {
    let dataNumber = new Intl.NumberFormat("de", {
      minimumFractionDigits: 2,
    }).format(Number(number));
    return dataNumber.replace(",00", "");
  }

  public setPreviewHtml(html, text: string): string {
    html = html.split("\n").join("");
    html = html.replace("#BODY#", text);
    return html;
  }

  public generateReportPdf(canvasChart, table, title, description, colors, period, report): void {
    const tenant = this.currentTenant && this.currentTenant.tenant ? this.currentTenant.tenant : this.jwtAuth.user.company;
    const regex = /\//g;
    const doc = new jsPDF();
    // report
    const optionTitle: TextOptionsLight = { align: "left" };
    doc.setTextColor("#ff0000");
    doc.setFontSize(12);
    doc.text(`S-Trust Nutzungsstatistik für ${tenant}`, 15, 20, optionTitle);

    // title
    doc.setFontSize(16);
    const reportTitle = this.translate.instant(title);
    const reportDescription = this.translate.instant(description);
    doc.text(reportTitle, 15, 30, optionTitle);

    // period
    doc.setTextColor("#444444");
    doc.setFontSize(12);

    doc.text(`${period.from.replace(regex, ".")} - ${period.to.replace(regex, ".")}`, 15, 45);

    // img - chart
    doc.addImage(canvasChart, "PNG", 20, 40, 180, 80, null, "NONE", 0);

    // description
    const strArr = doc.splitTextToSize(reportDescription, 150);
    doc.text(strArr, 15, 135);

    // table
    if (report === 3) {
      autoTable(doc, {
        headStyles: {
          fillColor: [102, 102, 102],
        },
        head: table.tableHeaders,
        body: table.tableResults,
        startY: 150,
      });
    } else {
      autoTable(doc, {
        headStyles: {
          fillColor: [102, 102, 102],
        },
        html: table,
        startY: 150,
      });
    }
    window.open(doc.output("bloburl").toString(), "_blank");
  }

  public generateAllReportPdf(reports, tenantTitle: string): void {
    const doc = new jsPDF();
    const reportSize = reports.length - 1;
    const regex = /\//g;
    reports.forEach((report, index) => {
      // report
      const optionTitle: TextOptionsLight = {
        align: "left",
      };
      doc.setTextColor("#ff0000");
      doc.setFontSize(12);
      doc.text(tenantTitle, 15, 20, optionTitle);

      // title
      doc.setFontSize(16);
      const reportTitle = this.translate.instant(report.title);
      const reportDescription = this.translate.instant(report.description);
      doc.text(reportTitle, 15, 30, optionTitle);

      // period
      doc.setTextColor("#444444");
      doc.setFontSize(12);

      doc.text(`${report.period.from.replace(regex, ".")} - ${report.period.to.replace(regex, ".")}`, 15, 45);

      // img - chart
      doc.addImage(report.canvasChart, "PNG", 20, 40, 160, 80, null, "NONE", 0);

      // description
      const strArr = doc.splitTextToSize(reportDescription, 150);
      doc.text(strArr, 15, 135);

      // table
      autoTable(doc, {
        headStyles: {
          fillColor: [102, 102, 102],
        },
        head: report.tableHeaders,
        body: report.tableResults,
        startY: 160,
      });
      if (reportSize !== index) {
        doc.addPage();
      }
    });
    window.open(doc.output("bloburl").toString(), "_blank");
  }

  public emailsValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (c.value.length < 4) {
      return null;
    } else {
      let error = false;
      let emails = c.value.split(";");
      let emailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;
      emails.forEach((email) => {
        if (!email.match(emailformat)) {
          error = true;
        }
      });
      return error ? { badEmail: true } : null;
    }
  }

  public formatDateToString(date: any): String {
    if (date && typeof date === "object") {
      if (typeof date.toDate === "function") {
        const d = date.toDate();
        return d.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" });
      } else {
        return date.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" });
      }
    } else {
      return null;
    }
  }
}
