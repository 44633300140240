import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RelativeTimePipe } from "./relative-time.pipe";
import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from "./get-value-by-key.pipe";
import { ReportUnitsPipe } from "./report-units.pipe";
import { AccountingUnitsPipe } from "./accounting-units.pipe";

const pipes = [RelativeTimePipe, ExcerptPipe, GetValueByKeyPipe, ReportUnitsPipe, AccountingUnitsPipe];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes,
})
export class SharedPipesModule {}
