import { Component, OnInit, AfterViewInit, Renderer2, HostListener } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

import { RoutePartsService } from "./shared/services/route-parts.service";

import { filter } from "rxjs/operators";
import { UILibIconService } from "./shared/services/ui-lib-icon.service";
import { Subject } from "rxjs";
import { JwtAuthService } from "./shared/services/auth/jwt-auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = "S-Trust Backend";
  pageTitle = "";
  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor(public title: Title, private router: Router, private activeRoute: ActivatedRoute, private routePartsService: RoutePartsService, private iconService: UILibIconService, private jwtAuth: JwtAuthService) {
    iconService.init();
    this.setTimeout();
    this.userInactive.subscribe(() => {
      console.log(`user has been inactive for ${this.jwtAuth.TIMEOUT} min`);
      this.jwtAuth.signout();
    });
  }

  ngOnInit() {
    this.changePageTitle();
  }

  ngAfterViewInit() {}

  @HostListener("window:mousemove") refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  setTimeout() {
    const millisecondsTimeout = this.jwtAuth.TIMEOUT * 60000;
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), millisecondsTimeout);
  }

  changePageTitle() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
