import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { HomeComponent } from "./views/home/home/home.component";

export const rootRouterConfig: Routes = [
  {
    path: "",
    redirectTo: "sessions/signin",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () => import("./views/sessions/sessions.module").then((m) => m.SessionsModule),
        data: { title: "Session" },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,

    children: [
      {
        path: "home",
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "administration",
        loadChildren: () => import("./views/administration/administration.module").then((m) => m.AdministrationModule),
        // data: { title: 'Administration', breadcrumb: 'ADMINSTRATION'} ,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
      },
      {
        path: "reporting",
        loadChildren: () => import("./views/reporting/reporting.module").then((m) => m.ReportingModule),
        canActivate: [AuthGuard],
      },
      {
        path: "nutzungsstatistik",
        loadChildren: () => import("./views/reporting/reporting.module").then((m) => m.ReportingModule),
        canActivate: [AuthGuard],
      },
      {
        path: "user-interaction",
        loadChildren: () => import("./views/user-interaction/user-interaction.module").then((m) => m.UserInteractionModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
      },
      {
        path: "pre-und-aftersales",
        loadChildren: () => import("./views/user-interaction/user-interaction.module").then((m) => m.UserInteractionModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
      },
      {
        path: "accounting",
        loadChildren: () => import("./views/accounting/accounting.module").then((m) => m.AccountingModule),
        canActivate: [AuthGuard],
      },
      {
        path: "abrechung",
        loadChildren: () => import("./views/accounting/accounting.module").then((m) => m.AccountingModule),
        canActivate: [AuthGuard],
      },
      {
        path: "promotion",
        loadChildren: () => import("./views/promotion/promotion.module").then((m) => m.PromotionModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
      },
      {
        path: "kundenfreischaltung",
        loadChildren: () => import("./views/promotion/promotion.module").then((m) => m.PromotionModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/signin",
  },
];
