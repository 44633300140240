import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private jwtAuth: JwtAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = this.jwtAuth.token || this.jwtAuth.ls.getItem(this.jwtAuth.JWT_TOKEN);
    var changedReq;
    if (token) {
      changedReq = req.clone({
        setHeaders: {
          JSESSIONID: `${this.jwtAuth.ls.getItem(this.jwtAuth.SESSION_ID)}`,
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      changedReq = req;
    }
    return next.handle(changedReq);
  }
}
