import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "./shared.service";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  constructor(public shared: SharedService) {}

  publishNavigationChange(tenant) {
    let company = tenant.hasOwnProperty("company") ? tenant.company : tenant.hasOwnProperty("tenant") ? tenant.tenant : "";
    this.iconMenu = [];
    let permision = this.shared.jwtAuth.getPermissions();
    if (company === this.shared.jwtAuth.DSV_USER) {
      if (permision.includes("ADM") || permision.includes("ADMG")) {
        let sub = [{ name: "NAVIGATION.USERS", state: "benutzer" }];
        if (permision.includes("ADMG")) {
          sub.push({ name: "NAVIGATION.TENANTS", state: "institute" });

          if (this.shared.jwtAuth.isDsvUser()) {
            sub.push({ name: "NAVIGATION.SETTINGS", state: "einstellungen" });
          }
          sub.push({ name: "NAVIGATION.BLACKLIST", state: "blacklist" });
        }

        let admin = {
          name: "NAVIGATION.ADMINSTRATION",
          type: "dropDown",
          icon: "playlist_add_check",
          state: "administration",
          sub: sub,
        };
        this.iconMenu.push(admin);
      }

      if (permision.includes("UI") || permision.includes("UIG")) {
        let ui = {
          name: "NAVIGATION.USER-INTERACTION",
          type: "dropDown",
          icon: "code",
          state: "pre-und-aftersales",
          sub: [
            { name: "NAVIGATION.USER-EMAILS", state: "nutzer-emails" },
            { name: "NAVIGATION.SYSTEM-EMAILS", state: "system-emails" },
          ],
        };
        this.iconMenu.push(ui);
      }

      if (permision.includes("RE") || permision.includes("REG")) {
        this.iconMenu.push({
          name: "NAVIGATION.REPORTING",
          type: "link",
          icon: "insert_chart",
          state: "nutzungsstatistik",
        });
      }

      if (permision.includes("ACC") || permision.includes("ACCG")) {
        this.iconMenu.push({
          name: "NAVIGATION.ACCOUNTING",
          type: "link",
          icon: "description",
          state: "abrechung",
        });
      }
      if (permision.includes("PROM") || permision.includes("PROMG")) {
        let sub = [{ name: "NAVIGATION.USER-LOG", state: "kundeninformation" }];
        let prom = {
          name: "NAVIGATION.PROMOTION",
          type: "dropDown",
          icon: "wb_sunny",
          state: "kundenfreischaltung",
          sub: sub,
        };
        this.iconMenu.push(prom);
      }
    } else {
      if (permision.includes("ADM") || permision.includes("ADMG")) {
        let admin = {
          name: "NAVIGATION.ADMINSTRATION",
          type: "dropDown",
          icon: "playlist_add_check",
          state: "administration",
          sub: [{ name: "NAVIGATION.USERS", state: "benutzer" }],
        };
        this.iconMenu.push(admin);
      }

      if ((permision.includes("RE") || permision.includes("REG")) && (tenant.contractType === null || tenant.contractType === "BASIC" || tenant.contractType === "ADVANTAGE_PROGRAM")) {
        this.iconMenu.push({
          name: "NAVIGATION.REPORTING",
          type: "link",
          icon: "insert_chart",
          state: "nutzungsstatistik",
        });
      }

      if ((permision.includes("ACC") || permision.includes("ACCG")) && (tenant.contractType === null || tenant.contractType === "ADVANTAGE_PROGRAM")) {
        this.iconMenu.push({
          name: "NAVIGATION.ACCOUNTING",
          type: "link",
          icon: "description",
          state: "abrechung",
        });
      }
    }

    this.menuItems.next(this.iconMenu);
  }
}
