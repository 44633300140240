import { Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html"
})
export class FooterComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  public openImprintDialog(): void {
    this.dialog.open(ImprintlDialog);
  }

  public openDataProtectionDialog(): void {
    this.dialog.open(DataProtectionDialog);
  }
}

@Component({
  template: `
    <div class="imprint-container">
      <h1>S-Trust Impressum</h1>
      <section>
        <h5>S-Communication Services GmbH</h5>
        <p>Friedrichstraße 50, 10117 Berlin</p>
        <p>Telefon +49 30 246 36-700</p>
        <p>Telefax +49 30 246 36-701</p>
        <p>E-Mail: <a class="link" href="mailto:info@s-trust.de">info@s-trust.de</a></p>
      </section>

      <section>
        <h5>Geschäftsführung:</h5>
        <p>Jochen Maetje</p>
        <p>Tobias Schlösser</p>
      </section>

      <section>
        <h5>Sitz der Gesellschaft:</h5>
        <p>Berlin</p>
      </section>

      <section>
        <h5>Handelsregister:</h5>
        <p>HRB 244713 B</p>
        <p>Amtsgericht Berlin (Charlottenburg)</p>
      </section>

      <section>
        <h5>Bankverbindung:</h5>
        <p>Baden-Württembergische Bank (BW-Bank)</p>
        <p>IBAN: DE12 6005 0101 0002 3007 77</p>
        <p>BIC: SOLADEST600</p>
        <br />
        <p>Berliner Sparkasse</p>
        <p>IBAN: DE73 1005 0000 0630 0147 10</p>
        <p>BIC: BELADEBEXXX</p>
      </section>

      <section>
        <h5>USt. ID-Nr.:</h5>
        <p>DE213473069</p>
      </section>
    </div>
  `
})
export class ImprintlDialog {
  constructor() {}
}

@Component({
  template: `
    <div class="data-protection-container">
      <h1>Hinweise zum Datenschutz der S-Communication Services GmbH</h1>
      <!-- SECTION -->
      <section>
        <h5 class="title">Verantwortlicher:</h5>
        <p>
          Der Verantwortliche im Sinne der EU Datenschutz-Grundverordnung (DSGVO), des Bundesdatenschutzgesetzes (BDSG) und etwaiger sonstiger nationalen Datenschutzgesetze oder datenschutzrechtlicher
          Bestimmungen ist die
        </p>
        <div class="info">
          <p>S-Communication Services GmbH</p>
          <p>(im Folgenden „Anbieter“)</p>
          <p>Friedrichstraße 50, 10117 Berlin</p>
          <p>Telefon +49 30 246 36-700</p>
          <p>Telefax +49 30 246 36-701</p>
          <p>E-Mail: <a class="link" href="mailto:support@s-trust.de">support@s-trust.de</a></p>
          <p>Website: <a class="link" href="https://www.s-communication.de">https://www.s-communication.de</a></p>
        </div>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Personenbezogene Daten</h5>
        <p>
          Personenbezogene Daten sind Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person (Betroffener). Dazu gehören der Name, die
          E-Mail-Adresse oder die Telefonnummer sowie Daten über Vorlieben, Hobbies, getätigte Internet-Einkäufe oder Webseiten-Besuche, immer vorausgesetzt, dass diese Information mit einer Person
          verbunden ist oder in Verbindung gebracht werden kann.
        </p>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Allgemeines zur Datenverarbeitung</h5>
        <p>Umfang der Verarbeitung personenbezogener Daten</p>
        <p>Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur soweit dies zur Erbringung unserer Leistungen und etwaiger damit verbundener Nebenleistungen erforderlich ist.</p>
        <p>
          Die Verarbeitung personenbezogener Daten erfolgt regelmäßig nur nach Einwilligung der betroffenen Person. Ausnahmen gelten für solche Fälle, in denen die Verarbeitung der Daten durch
          gesetzliche Vorschriften gestattet oder geboten ist.
        </p>
        <p>
          Die vorliegende Datenschutzerklärung soll Ihnen Transparenz über die verarbeiteten personenbezogenen Daten verschaffen. Hierbei ist für jeden Anwendungsfall dasselbe Schema zugrunde gelegt:
        </p>
        <ul>
          <li>Beschreibung, Umfang der Datenverarbeitung</li>
          <li>Rechtsgrundlage</li>
          <li>Zweck</li>
          <li>Dauer der Speicherung</li>
          <li>Widerruf, Widerspruch und Ende der Verarbeitung</li>
        </ul>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h5>
        <p>
          Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient diese in Verbindung mit Art. 6 Abs. 1 Satz 1 Buchst. a und Art. 7 DSGVO
          als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
        </p>
        <p>
          Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 Satz 1 Buchst. b
          DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        </p>
        <p>
          Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 Satz 1 Buchst. c DSGVO
          als Rechtsgrundlage.
        </p>
        <p>
          Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
          Satz 1 Buchst. d DSGVO als Rechtsgrundlage.
        </p>
        <p>
          Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des
          Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 Satz 1 Buchst. f DSGVO als Rechtsgrundlage für die Verarbeitung.
        </p>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Datenlöschung, Einschränkung der Verarbeitung und Speicherdauer</h5>
        <p>
          Die personenbezogenen Daten der betroffenen Person werden gelöscht, wenn und sobald die Verarbeitung für den intendierten Verarbeitungszweck nicht mehr notwendig ist. Eine Speicherung kann
          darüber hinaus allerdings dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
          Verantwortliche unterliegt, vorgesehen wurde. Eine Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
          Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
        </p>
        <p>Die Verarbeitung der Daten wird eingeschränkt, wenn</p>
        <p>
          a) die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
          personenbezogenen Daten zu überprüfen,
        </p>
        <p>
          b) die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten ablehnt und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
          verlangt;
        </p>
        <p>
          c) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, die betroffene Person sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigt, oder
        </p>
        <p>
          d) die betroffene Person Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber
          denen der betroffenen Person überwiegen
        </p>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Schutz personenbezogener Daten durch technische und organisatorische Maßnahmen</h5>
        <p>
          Wir setzen auf Basis des vom Gesetz geforderten risikoorientierten Ansatzes technische und organisatorische Maßnahmen ein, um personenbezogene Daten gegen zufällige oder vorsätzliche
          Manipulationen, gegen Verlust, Zerstörung oder den Zugriff Unberechtigter zu schützen. Unsere Sicherheitsmaßnahmen werden unter anderem entsprechend der technologischen Entwicklung
          fortlaufend verbessert, sie werden getestet und regelmäßig durch interne wie externe Stellen überprüft.
        </p>
        <p>Unser Unternehmen / unser Rechenzentrum ist nach ISO 27001, ISO 9001, TSI, PS951 zertifiziert bzw. überprüft.</p>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Rechte der betroffenen Person</h5>
        <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie betroffene Person i. S. d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:</p>
        <b>1. Auskunftsrecht</b>
        <p>Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.</p>
        <p>Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:</p>
        <div class="sub-info">
          <p>(1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</p>
          <p>(2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</p>
          <p>(3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</p>
          <p>
            (4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
          </p>
          <p>
            (5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder
            eines Widerspruchsrechts gegen diese Verarbeitung;
          </p>
          <p>(6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</p>
          <p>(7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</p>
          <p>
            (8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über
            die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
          </p>
        </div>
        <p>
          Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In
          diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
        </p>

        <b>2. Recht auf Berichtigung</b>
        <p>
          Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder
          unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
        </p>

        <b>3. Recht auf Einschränkung der Verarbeitung</b>
        <p>Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:</p>
        <div class="sub-info">
          <p>
            (1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu
            überprüfen;
          </p>
          <p>(2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</p>
          <p>
            (3) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen benötigen, oder
          </p>
          <p>
            (4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
            überwiegen.
          </p>
        </div>
        <p>
          Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der
          Union oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <p>Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.</p>

        <b>4. Recht auf Löschung</b>
        <p>a) Löschungspflicht</p>
        <p>
          Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten
          unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
        </p>
        <div class="sub-info">
          <p>(1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</p>
          <p>
            (2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 Satz 1 Buchst. a oder Art. 9 Abs. 2 Buchst. a DSGVO stützte, und es fehlt an einer anderweitigen
            Rechtsgrundlage für die Verarbeitung.
          </p>
          <p>
            (3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21
            Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
          </p>
          <p>(4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</p>
          <p>
            (5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem
            der Verantwortliche unterliegt.
          </p>
          <p>(6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</p>
        </div>
        <p>b) Information an Dritte</p>
        <p>
          Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter
          Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die
          personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder
          Replikationen dieser personenbezogenen Daten verlangt haben.
        </p>
        <p>c) Ausnahmen</p>
        <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
        <div class="sub-info">
          <p>(1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</p>
          <p>
            (2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur
            Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
          </p>
          <p>(3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 Buchst. h und i sowie Art. 9 Abs. 3 DSGVO;</p>
          <p>
            (4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
            Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
          </p>
          <p>(5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
        </div>
        <b>5. Recht auf Unterrichtung</b>
        <p>
          Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie
          betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
          unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
        </p>
        <p>Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.</p>

        <b>6. Recht auf Datenübertragbarkeit</b>
        <p>
          Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
          erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu
          übermitteln, sofern
        </p>
        <div class="sub-info">
          <p>
            (1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 Satz 1 Buchst. a DSGVO oder Art. 9 Abs. 2 Buchst. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 Satz 1 Buchst. b DSGVO
            beruht und
          </p>
          <p>(2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
        </div>
        <p>
          In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen
          übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
        </p>
        <p>
          Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder
          in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
        </p>

        <b>7. Widerspruchsrecht</b>
        <p>
          Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs.
          1 Satz 1 Buchst. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
        </p>
        <p>
          Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
          Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        </p>
        <p>
          Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
          personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
        </p>
        <p>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p>
        <p>
          Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter
          Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
        </p>

        <b>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</b>
        <p>
          Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis
          zum Widerruf erfolgten Verarbeitung nicht berührt.
        </p>

        <b>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</b>
        <p>
          Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber
          rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
        </p>
        <div class="sub-info">
          <p>(1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,</p>
          <p>
            (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung
            Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
          </p>
          <p>(3) mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>
        </div>
        <p>
          Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 Buchst. a oder g gilt und
          angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
        </p>
        <p>
          Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens
          das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
        </p>

        <b>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</b>
        <p>
          Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
          ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
          die DSGVO verstößt.
        </p>
        <p>
          Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines
          gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
        </p>
      </section>

      <!-- SECTION -->
      <section>
        <div class="info">
          <h5 class="title">Betrieblicher Datenschutzbeauftragter</h5>
          <p>
            Betrieblicher Datenschutzbeauftragter ist Herr Jochen Weller bei der Deutscher Sparkassen Verlag GmbH (Am Wallgraben 115, 70565 Stuttgart, Telefon: +49 711 782-21151). Sie erreichen das
            Team für Datenschutz und Informationssicherheit über unser hierfür zur Verfügung gestelltes
            <a style="color:red; text-decoration:none" class="link" href="https://www.dsv-gruppe.de/kontakt-datenschutz">Webformular</a>.
          </p>
        </div>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Webauftritt und Logfiles</h5>
        <h6>Beschreibung und Umfang der Datenverarbeitung</h6>
        <p>
          Bei jeder Nutzung unseres Webauftritts werden Daten zwischen dem Client (z. B. Ihrem Web-Browser) und dem Server (unser Webauftritt) ausgetauscht. Auf unserem Server werden hierfür die
          nachfolgenden Informationen gespeichert:
        </p>
        <ul>
          <li>Datum und Uhrzeit des Zugriffs</li>
          <li>Typ und Version Ihres Internet-Browsers</li>
          <li>Typ und Version des Betriebssystems</li>
          <li>volle IP-Adresse des Nutzers</li>
        </ul>
        <h6>Zweck</h6>
        <p>Diese Informationen werden von uns ausschließlich für die nachfolgenden Zwecke genutzt:</p>
        <ul>
          <li>Sicherstellung des Betriebs oder der Betriebssicherheit</li>
          <li>Analyse bei Verdacht auf eine rechtswidrige Nutzung unserer Webseite</li>
        </ul>
        <p>In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 Satz 1 Buchst. f DSGVO.</p>
        <h6>Rechtsgrundlage</h6>
        <p>Rechtsgrundlage für die vorübergehende Speicherung dieser Daten und der zugehörigen Logfiles ist Art. 6 Abs. 1 Satz 1 Buchst. f DSGVO.</p>
        <h6>Dauer der Speicherung</h6>
        <p>Die Daten werden gelöscht, sobald sie für die Erreichung des jeweiligen Zweckes nicht mehr erforderlich sind. Im Falle der Logfiles ist dies spätestens nach 30 Tagen der Fall.</p>
        <h6>Widerruf, Widerspruch und Ende der Verarbeitungen</h6>
        <p>
          Der Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles kann effektiv durch Verlassen der Internetseite widersprochen werden. Soweit personenbezogene
          Daten bereits gespeichert sind, richten Sie Ihren etwaigen Widerspruch bitte an die eingangs genannten Kontaktdaten.
        </p>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Newsletter</h5>
        <h6>Beschreibung, Umfang der Datenverarbeitung</h6>
        <p>Auf unserer Internetseite haben Sie die Möglichkeit, sich für kostenfreie Newsletter zu registrieren. Hierfür werden die folgenden Informationen an uns übermittelt:</p>
        <ul>
          <li>Datum und Uhrzeit der Anfrage</li>
          <li>IP-Adresse des aufrufenden Rechners</li>
          <li>E-Mail-Adresse</li>
          <li>Name(n)</li>
          <li>Anrede</li>
        </ul>
        <h6>Zweck</h6>
        <p>Mit unseren Newslettern lassen wir Ihnen Informationen, die auch werblichen Charakter haben können, zukommen.</p>
        <h6>Rechtsgrundlage</h6>
        <p>Sobald Sie sich für einen Newsletter registrieren, handelt es sich um eine Einwilligung im Sinne des Art. 6 Abs. 1 Satz 1 Buchst. a und Art. 7 DSGVO.</p>
        <h6>Dauer der Speicherung</h6>
        <p>
          Die Daten werden bis zum Widerruf der Einwilligung oder bis zu dem Moment gespeichert, wo für uns ersichtlich ist, dass einer der sonstigen in Art. 17 Abs. 1 DSGVO genannten Löschgründe
          vorliegt.
        </p>
        <h6>Widerruf, Widerspruch und Ende der Verarbeitung</h6>
        <p>Sie haben das Recht, jederzeit Widerspruch gegen die Verarbeitung Ihrer Daten zu Werbezwecken einzulegen (Art. 21 Abs. 2, 3 DSGVO).</p>
        <p>
          Um Ihre Einwilligung zu widerrufen, gibt es deshalb für jeden unserer Newsletter die Möglichkeit, diesen jederzeit wieder abzubestellen (Art. 7 Abs. 3 DSGVO). Wie Sie sich abmelden können,
          wird in jedem Newsletter erläutert.
        </p>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Erstellung eines Benutzerkontos</h5>
        <h6>Beschreibung, Umfang der Datenverarbeitung</h6>
        <p>Nur durch die Erstellung eines Benutzerkontos können Sie in der Webanwendung arbeiten und administrieren.</p>
        <hr />
        <br />
        <p>Von Anwendern, die ein Benutzerkonto haben, verarbeiten wir die folgenden Daten:</p>
        <ul>
          <li>Datum und Uhrzeit der Anlage</li>
          <li>IP-Adresse des aufrufenden Rechners</li>
          <li>E-Mail-Adresse</li>
        </ul>
        <p>Auf unserem mit „https“ gesicherten Angebot werden Ihre Daten sowohl bei der Erstellung des Benutzerkontos als auch bei dessen späterer Verwendung verschlüsselt übertragen.</p>
        <h6>Zweck</h6>
        <p>Zweck der Datenverarbeitung ist es, Ihnen die mit dem Benutzerkonto Zugang zum Backend zu S-Trust zu gewähren. Bitte lesen Sie hierzu auch unsere aktuellen S-Trust Angebote.</p>
        <h6>Rechtsgrundlage</h6>
        <p>
          Durch die Erstellung des Benutzerkontos wird ein spezifisches Rechtsverhältnis begründet (Art. 6 Abs. 1 Satz 1 Buchst. b DSGVO). Außerdem kann die Datenverarbeitung auf einer
          Interessenabwägung beruhen (Art. 6 Abs. 1 Satz 1 Buchst. b DSGVO).
        </p>
        <h6>Dauer der Speicherung</h6>
        <p>
          Ihre Daten werden bis zur Kündigung Ihres Benutzerkontos oder bis zu dem Moment verarbeitet, wo für uns ersichtlich ist, dass einer der sonstigen in Art. 17 Abs. 1 DSGVO genannten
          Löschgründe vorliegt.
        </p>
        <h6>Widerruf, Widerspruch und Ende der Verarbeitung</h6>
        <p>Zur Beendigung der Verarbeitung können Sie das Benutzerkonto kündigen.</p>
      </section>

      <!-- SECTION -->
      <section>
        <h5 class="title">Kontaktmöglichkeiten</h5>
        <h6>Beschreibung und Umfang der Datenverarbeitung</h6>
        <p>Mit unserem Angebot bieten wir Ihnen die Möglichkeit, sich mit uns auf elektronischem Wege in Verbindung zu setzen. Hierfür werden die nachfolgenden Informationen erhoben:</p>
        <ul>
          <li>Datum und Uhrzeit der Anfrage</li>
          <li>IP-Adresse des aufrufenden Rechners</li>
          <li>Name, Vorname</li>
          <li>E-Mail-Adresse</li>
          <li>Telefonnummer</li>
        </ul>
        <h6>Zweck</h6>
        <p>Die Daten werden verarbeitet, um das uns übermittelte Anliegen zu bearbeiten.</p>
        <h6>Rechtsgrundlage</h6>
        <p>Rechtsgrundlage für die Verarbeitung Ihrer Daten und der zugehörigen Logfiles ist Art. 6 Abs. 1 lit. f DSGVO und, soweit sie eine solche abgegeben haben, Ihre Einwilligung.</p>
        <h6>Dauer der Speicherung</h6>
        <p>
          Für unser Unternehmen ist generell angewiesen und teilweise ergänzend technisch sichergestellt, dass Daten den Vorgaben des Art. 17 Abs. 1 DSGVO entsprechend gelöscht werden. Wie und wann
          gelöscht wird, hängt vom genutzten Kommunikationskanal und den datenverarbeitenden Systemen ab (z. B. werden viele Anfragen an unser Ticketsystem weitergeleitet, damit wir die
          Datenverarbeitung auf genau ein System beschränken können). Für das vorliegende Angebot gilt insoweit:
        </p>
        <hr />
        <br />
        <p>
          Die Daten werden gelöscht, sobald sie für die Erreichung des jeweiligen Zwecks nicht mehr erforderlich sind, spätestens jedoch nach 10 Jahren, gerechnet ab dem Ende des Kalenderjahrs ihrer
          Erhebung.
        </p>
        <h6>Widerruf, Widerspruch und Ende der Verarbeitung</h6>
        <p>
          Ihre Daten werden bis zum Widerspruch gegen die Verarbeitung, des Widerrufs Ihrer Einwilligung oder bis zu dem Moment gespeichert, wo für uns ersichtlich ist, dass einer der sonstigen in
          Art. 17 Abs. 1 DSGVO genannten Löschgründe vorliegt.
        </p>
      </section>
    </div>
  `
})
export class DataProtectionDialog {
  constructor() {}
}
