import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EnvService {
  public envFileLoaded = false;
  public production: false;
  public develop: false;
  public qa: false;
  public demo: false;
  public local: false;
  public dsv_user: "";
  public timeout: number;
  public version: "";
  public apiAuthURL: "";
  public apiAdministrationURL: "";
  public apiReportingURL: "";
  public apiUserInteractionURL: "";
  public apiAccountingURL: "";
  public apiPromotionURL: "";

  constructor() {}
}
