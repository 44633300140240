import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "accountingUnits",
})
export class AccountingUnitsPipe implements PipeTransform {
  transform(value: string, type: number): string {
    if (value) {
      if (type === 0) {
        let dataNumber = new Intl.NumberFormat("de", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(Number(value));
        return dataNumber.replace(",00", "");
      } else {
        let dataNumber = new Intl.NumberFormat("de", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(Number(value));
        return dataNumber;
      }
    } else {
      if (type === 0) {
        return "0";
      } else {
        return "0,00";
      }
    }
  }
}
