<div class="sidenav-hold" id="sidenav-menu">
  <ul appDropdown class="sidenav">
    <li *ngFor="let item of menuItems;let i = index" [id]="'menu-'+i+''" [attr.data-index]="'menu-'+i+''" appDropdownLink routerLinkActive="open">
      <mat-divider *ngIf="i===0"></mat-divider>
      <div *ngIf="!item.disabled" class="lvl1">
        <!-- Link -->
        <a *ngIf="item.type === 'link'" routerLink="/{{item.state}}" [routerLinkActive]="['link-active']" appDropdownToggle matRipple>
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
          <span class="item-name lvl1">{{item.name | translate | uppercase}}</span>
        </a>

        <!-- DropDown -->
        <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple>
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
          <span class="item-name lvl1">{{item.name | translate | uppercase}}</span>
          <span fxFlex></span>
          <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
        </a>
        <!-- LEVEL 2 -->
        <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
          <li *ngFor="let itemLvL2 of item.sub;let j=index" [id]="'sub-menu-'+j+''" [attr.data-index]="'sub-menu-'+j+''" appDropdownLink routerLinkActive="open">
            <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" [routerLinkActive]="['link-active']" appDropdownToggle *ngIf="itemLvL2.type !== 'dropDown'" matRipple>
              <span class="item-name lvl2">{{itemLvL2.name | translate }}</span>
              <span fxFlex></span>
            </a>

            <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
              <span class="item-name lvl2">{{itemLvL2.name | translate }}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>

            <!-- LEVEL 3 -->
            <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
              <li *ngFor="let itemLvL3 of itemLvL2.sub;let jj=index" [id]="'sub-sub-menu-'+jj+''" [attr.data-index]="'sub-sub-menu-'+jj+''" appDropdownLink routerLinkActive="open">
                <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}" appDropdownToggle [routerLinkActive]="['link-active']" matRipple>
                  <span class="item-name lvl3">{{itemLvL3.name | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <mat-divider></mat-divider>
    </li>
  </ul>
</div>
