import { Directive, Host, Self, Optional, OnDestroy, OnInit } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Subscription } from "rxjs";
import { MatSidenav } from "@angular/material/sidenav";

@Directive({
  selector: "[EgretSideNavToggle]",
})
export class EgretSideNavToggleDirective implements OnInit, OnDestroy {
  isMobile;
  screenSizeWatcher: Subscription;
  constructor(private mediaObserver: MediaObserver, @Host() @Self() @Optional() public sideNav: MatSidenav) {}

  ngOnInit() {
    this.initSideNav();
  }

  ngOnDestroy() {
    if (this.screenSizeWatcher) {
      this.screenSizeWatcher.unsubscribe();
    }
  }

  updateSidenav() {
    const self = this;
    setTimeout(() => {
      self.sideNav.opened = !self.isMobile;
      self.sideNav.mode = self.isMobile ? 'over' : 'side';
    });
  }

  checkIfMobile() {
    this.isMobile = this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm');
  }

  initSideNav() {
    this.checkIfMobile();
    this.updateSidenav();

    this.screenSizeWatcher = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      this.isMobile = changes.some(change => change.mqAlias === 'xs' || change.mqAlias === 'sm');
      this.updateSidenav();
    });
  }
}
