import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "app/shared/services/navigation.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit {
  constructor(private router: Router, private navService: NavigationService) {}

  ngOnInit(): void {
    let route = "/";
    let r = this.navService.iconMenu[0];
    if (r && r.sub) {
      route += `${r.state}/${r.sub[0].state}`;
    } else {
      route += r.state;
    }
    this.router.navigateByUrl(route);
  }
}
