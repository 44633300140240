import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-set-password-dialog",
  templateUrl: "./set-password-dialog.component.html",
})
export class SetPasswordDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
