import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ThemeService } from "../../services/theme.service";
import { LayoutService } from "../../services/layout.service";
import { TranslateService } from "@ngx-translate/core";
import { JwtAuthService } from "../../services/auth/jwt-auth.service";
import { SharedService } from "app/shared/services/shared.service";

class Tenant {
  blz: string;
  id: string;
  tenant: string;
}

@Component({
  selector: "app-header-side",
  templateUrl: "./header-side.template.html",
})
export class HeaderSideComponent implements OnInit {
  @ViewChild("searchInput") searchInput: ElementRef;
  public availableLangs = [
    {
      name: "DE",
      code: "de",
      flag: "flag-icon-de",
    },
    {
      name: "EN",
      code: "en",
      flag: "flag-icon-gb",
    },
  ];
  currentLang = this.availableLangs[0];
  public isLocal: boolean = false;
  public egretThemes;
  public layoutConf: any;
  public hasTenantList: boolean = false;
  constructor(private themeService: ThemeService, private layout: LayoutService, public translate: TranslateService, public jwtAuth: JwtAuthService, public sharedService: SharedService) {
    this.isLocal = this.sharedService.environment.local;
  }
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
  }

  setFocusInput() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 500);
  }

  setFocusInputFast() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0);
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }
}
