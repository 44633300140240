<mat-toolbar class="topbar mat-bg-cardx">
  <!-- Sidenav toggle button -->
  <button *ngIf="layoutConf.sidebarStyle !== 'compact'" mat-icon-button id="sidenavToggle" (click)="toggleSidenav()" matTooltip="Toggle Hide/Open">
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>

  <!-- Language Switcher -->
  <button *ngIf="isLocal" mat-button [matMenuTriggerFor]="menu">
    <span class="flag-icon {{currentLang.flag}} mr-8"></span>
    <!-- <span>{{currentLang.name}}</span> -->
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
      <span class="flag-icon mr-8 {{lang.flag}}"></span>
      <span>{{lang.name}}</span>
    </button>
  </mat-menu>

  <ng-container *ngIf="sharedService.tenantList.length>0 && sharedService.currentTenant; else noTenantList">
    <button style="background: #8080801a" mat-button [matMenuTriggerFor]="tenans" (menuOpened)="setFocusInput()">
      <span class="topbar-name" style="margin: 0; margin-right: 10px">{{sharedService.currentTenant.tenant}}</span>
      <mat-icon class="menu-caret" style="transform: rotate(90deg)">keyboard_arrow_right</mat-icon>
    </button>
    <mat-menu #tenans="matMenu" (closed)="sharedService.closeSuggestions($event);">
      <div style="padding-left: 12px">
        <input
          #searchInput
          [attr.data-index]="'input-search-tenant'"
          class="input-strust-line input-transparent"
          style="width: 90%"
          type="text"
          maxlength="20"
          [(ngModel)]="sharedService.searchText"
          (click)="$event.stopPropagation();$event.preventDefault();"
          (keyup)="sharedService.searchTenant($event)"    
          (change)="setFocusInputFast()"      
        />
        <mat-icon class="icon-search" style="right: 25px">search</mat-icon>
      </div>
      <button mat-menu-item *ngFor="let tenant of sharedService.tenantList.slice(0,6); let i = index" [id]="'dropdown-tenant-'+i+''" [attr.data-index]="'dropdown-tenant-'+i+''" (click)="sharedService.setTenant(tenant)">
        <span>{{tenant.tenant}}</span>
      </button>
    </mat-menu>
  </ng-container>
  <ng-template #noTenantList>
    <span class="topbar-name"> {{jwtAuth?.user?.company}}</span>
  </ng-template>
</mat-toolbar>
