import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { User } from "../../models/user.model";
import { of, BehaviorSubject, throwError, Subject, Observable } from "rxjs";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";
import { EnvService } from "app/env.service";

export interface IToken {
  exp?: number;
  iat?: number;
  permissions?: String[];
  sub?: string;
}

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  DSV_USER: String = this.environment.dsv_user;
  TIMEOUT: number = this.environment.timeout;
  info = null;
  token;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = new BehaviorSubject<User>(this.user);
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = "JWT_TOKEN";
  APP_USER = "USER";
  SESSION_ID = "JSESSIONID";
  private tokenLive;
  private tokenTimer: Subject<any> = new Subject();
  constructor(public ls: LocalStoreService, private http: HttpClient, private router: Router, private route: ActivatedRoute, private environment: EnvService) {
    this.route.queryParams.subscribe((params) => (this.return = params["return"] || "/"));
    this.tokenTimer.subscribe(() => {
      console.log("Refresh token");
      this.refreshToken().subscribe((response) => {
        this.token = response.jwttoken;
        this.ls.setItem(this.JWT_TOKEN, response.jwttoken);
        clearTimeout(this.tokenLive);
        this.setTimeout();
      });
    });
  }

  public signin(username: string, password) {
    this.signingIn = true;
    username = username.trim();
    let headers = new HttpHeaders();
    headers = headers.set("access-control-expose-headers", "x-total-count");
    return this.http.post(`${this.environment.apiAuthURL}/login`, { username, password }, { headers: headers, observe: "response" }).pipe(
      map((res: HttpResponse<any>) => {
        this.setUserAndToken(res.body.jwttoken, res.body.userInformation, res.headers.get("Pragma"), !!res);
        this.signingIn = false;
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public forgotPassword(data) {
    return this.http.post(`${this.environment.apiAdministrationURL}/forgotpwd`, { username: data.username }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public checkGUID(guid, username) {
    return this.http.post(`${this.environment.apiAuthURL}/check-temporary-password?guid=${guid}`, { idUser: username }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public setPassword(data) {
    return this.http.post(`${this.environment.apiAuthURL}/setpwd?guid=${data.id}`, { idUser: data.username, password: data.passwordGroup.password }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public setPasswordUserAuth() {
    return this.http.post(`${this.environment.apiAuthURL}/changepwd`, {}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getAdminList(tenant?) {
    let url = `${this.environment.apiAuthURL}/admins`;
    if (tenant && tenant.id) {
      url = `${url}?tenantId=${tenant.id}`;
    }
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  private refreshToken() {
    return this.http.post(`${this.environment.apiAuthURL}/refresh-token`, null).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public checkTokenIsValid() {
    return of(this.getUser()).pipe(
      map((profile: User) => {
        this.setUserAndToken(this.ls.getItem(this.JWT_TOKEN), profile, this.ls.getItem(this.SESSION_ID), true);
        this.signingIn = false;
        return profile;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  public callSignoutBackend(): Observable<any> {
    const url = `${this.environment.apiAuthURL}/log-out`;
    return this.http.post(url, {}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public signout() {
    this.callSignoutBackend().subscribe(
      (response) => {
        this.removeUserAndToken();
        this.router.navigateByUrl("sessions/signin");
      },
      (error) => {
        this.removeUserAndToken();
        this.router.navigateByUrl("sessions/signin");
      }
    );
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    let id = this.ls.getItem(this.SESSION_ID);
    let tokenEnc = this.ls.getItem(this.JWT_TOKEN);
    if (id && tokenEnc) {
      let encryptionKey = "Ha7wfB9SVEoohLgC9MfzIda5KPTRkJQOv894p9InL2";
      let iv = id;
      let salt = id;
      let token = this.decrypt(salt, iv, encryptionKey, tokenEnc);
      return token;
    } else {
      return tokenEnc;
    }
  }

  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  isSuperAdmin(): boolean {
    let response = false;
    const permissions = this.getPermissions();
    response = permissions.includes("ADM") && permissions.includes("ADMG");
    return response;
  }

  hasTenantList(): boolean {
    let response = false;
    const permissions = this.getPermissions();
    response = permissions.includes("ACCG") || permissions.includes("REG") || permissions.includes("ADMG");
    return response;
  }

  isAccountingGlobalUser(): boolean {
    let response = false;
    const permissions = this.getPermissions();
    response = permissions.includes("ACCG");
    return response;
  }

  isDsvUser(): boolean {
    return this.user.company === this.DSV_USER;
  }

  getPermissions() {
    let token = this.getJwtToken();
    let decodedToken = jwt_decode<IToken>(token);
    let permision = decodedToken && decodedToken?.permissions ? decodedToken.permissions : [];
    return permision;
  }

  setUserAndToken(token: String, user: User, session: string, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.APP_USER, user);
    this.ls.setItem(this.SESSION_ID, session);
    this.token = token;
    this.ls.setItem(this.JWT_TOKEN, token);
    this.setTimeout();
  }

  setTimeout() {
    // 5 mins
    this.tokenLive = setTimeout(() => this.tokenTimer.next(undefined), 300000);
  }

  removeUserAndToken() {
    this.isAuthenticated = false;
    this.token = null;
    this.user = null;
    this.user$.next(null);
    this.ls.clear();
  }

  private generateKey(salt, passPhrase) {
    let key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), { keySize: 4, iterations: 1000 });
    return key;
  }

  private decrypt(salt, iv, passPhrase, cipherText) {
    let key = this.generateKey(salt, passPhrase);
    let cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(cipherText),
    });
    let decrypted = CryptoJS.AES.decrypt(cipherParams, key, { iv: CryptoJS.enc.Hex.parse(iv) });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
