<div class="sidebar-panel" id="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <div class="sidebar-hold">
      <!-- App Logo -->
      <div class="branding">
        <div style="margin: 0 auto">
          <img src="assets/images/logos/logo-strust.png" alt="" class="app-logo" />
        </div>
        <!-- <span class="app-logo-text">BACK-END</span>  -->
      </div>

      <!-- Sidebar user -->
      <div class="app-user">
        <div class="app-user-box">
          <span class="app-user-name">
            {{ shared.jwtAuth?.user?.username }}
          </span>
          <!-- logout icon -->
          <div class="logout-btn cp">
            <div class="ch" style="text-align: start">
              <span (click)="shared.jwtAuth.signout()" style="font-size: 12px" class="text-white" mat-icon-button mat-xs-button routerLink="/sessions/signin">
                <mat-icon class="icon-logout">exit_to_app</mat-icon>
                Abmelden
              </span>
              <br />
              <span (click)="openSetPasswordDialog()" style="font-size: 12px" class="text-white" mat-icon-button mat-xs-button>
                <mat-icon class="icon-logout">keyboard_tab</mat-icon>
                Passwort ändern
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Navigation -->
      <app-sidenav [items]="menuItems" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>

      <!-- TENANT ADMIN -->
      <div *ngIf="adminList" class="sidebar-admins-list">
        <span>{{ "MSG-ADMINS" | translate }}:</span>
        <div *ngFor="let admin of adminList">
          <a href="mailto:{{ admin }}">{{ admin }}</a>
        </div>
      </div>

      <div class="cp" style="position: absolute; bottom: 0; color: white">
        <span class="ch" style="font-size: 12px">{{ shared.environment.version }}</span>
      </div>
    </div>
  </div>
</div>
