<footer class="main-footer">
  <div class="container-dynamic d-flex">
    <div style="display: grid">
      <div style="width: 300px">
        <img alt="logo-s-trust" src="assets/images/logos/logo-dsv-gruppe-red.png" style="width: 150px; float: right" />
      </div>
      <div style="background-color: rgb(230, 230, 230); border-radius: 15px">
        <div style="margin-left: 15px; margin-right: 15px">
          <div style="display: flex">
            <div class="footer-container-link">
              <div class="footer-link border-r"><a href="mailto:info@s-trust.de">Kontakt</a></div>
            </div>
            <div class="footer-container-link">
              <div (click)="openImprintDialog()" class="footer-link padding-l border-r"><a>Impressum</a></div>
            </div>
            <div class="footer-container-link">
              <div (click)="openDataProtectionDialog()" class="footer-link padding-l"><a>Datenschutzhinweise</a></div>
            </div>
          </div>
          <div style="margin-bottom: 10px">
            <div>© S-Communication Services GmbH. Alle Rechte vorbehalten.</div>
            <div>Vervielfältigung nur mit Genehmigung der S-Communication Services GmbH.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
