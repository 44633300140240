import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class AuthGuard  {
  constructor(private router: Router, private jwtAuth: JwtAuthService) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.jwtAuth.isLoggedIn()) {
      let permision = this.jwtAuth.getPermissions();
      //HOME
      if (state.url === "/home") return true;
      //REPORTING
      if (state.url === "/reporting" && (permision.includes("RE") || permision.includes("REG"))) return true;
      if (state.url === "/nutzungsstatistik" && (permision.includes("RE") || permision.includes("REG"))) return true;
      if (state.url === "/reporting/detail" && (permision.includes("RE") || permision.includes("REG"))) return true;
      if (state.url === "/nutzungsstatistik/detail" && (permision.includes("RE") || permision.includes("REG"))) return true;
      //ADMINISTRATION
      if (state.url === "/administration/benutzer" && (permision.includes("ADM") || permision.includes("ADMG"))) return true;
      if (state.url === "/administration/institute" && permision.includes("ADMG")) return true;
      if (state.url === "/administration/einstellungen" && permision.includes("ADMG")) return true;
      if (state.url === "/administration/blacklist" && (permision.includes("ADMG") || permision.includes("UI") || permision.includes("UIG"))) return true;
      //ACCOUNTING
      if (state.url === "/accounting" && (permision.includes("ACC") || permision.includes("ACCG"))) return true;
      if (state.url === "/abrechung" && (permision.includes("ACC") || permision.includes("ACCG"))) return true;
      //PROMOTION
      if (state.url === "/promotion/kundeninformation" && (permision.includes("PROM") || permision.includes("PROMG"))) return true;
      if (state.url === "/kundenfreischaltung/kundeninformation" && (permision.includes("PROM") || permision.includes("PROMG"))) return true;

      //USER INTERACTION
      if (state.url === "/user-interactio/nutzer-emails" && (permision.includes("UI") || permision.includes("UIG"))) return true;
      if (state.url === "/user-interactio/system-emails" && (permision.includes("UI") || permision.includes("UIG"))) return true;
      if (state.url === "/pre-und-aftersales/nutzer-emails" && (permision.includes("UI") || permision.includes("UIG"))) return true;
      if (state.url === "/pre-und-aftersales/system-emails" && (permision.includes("UI") || permision.includes("UIG"))) return true;

      return false;
    } else {
      this.router.navigateByUrl("/sessions/signin");
      return false;
    }
  }
}
